import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { icons, images } from "../assets";

export default function Home() {
  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 270px)",
        width: "100%",
        background: "#EDE6DC",
      }}
    >
      <Box maxWidth={1324} sx={{ margin: "auto", width: "100%" }}>
        <Grid
          container
          justifyContent={{
            xs: "center",
            md: "space-between",
          }}
        >
          <Grid
            xs={12}
            md={6}
            px={{ md: 10, xs: "20px" }}
            py={{ md: 10, xs: 2 }}
            mt={{ md: 17, xs: 0 }}
          >
            <img alt="rewards" src={images.banner} style={{ width: "100%" }} />
            <Box
              sx={{ fontSize: 40, fontWeight: 300, fontFamily: "Inter" }}
              mt={"18px"}
            >
              Welcome to MUSE.
            </Box>
            <Stack spacing={2} mt={4} width={"100%"} direction={"row"}>
              <Link href={"https://apps.apple.com/au/app/muse/id1596725411"}>
                <img
                  alt="appStore"
                  src={icons.appStore}
                  style={{ width: 160 }}
                />
              </Link>
              {/* <Link
                href={
                  "https://play.google.com/store/apps/details?id=com.museapp"
                }
              >
                <img
                  alt="googlePlay"
                  src={icons.googlePlay}
                  style={{ width: 160 }}
                />
              </Link> */}
            </Stack>
          </Grid>
          <Grid
            xs={12}
            md={6}
            px={{ md: 6, xs: "20px" }}
            py={{ md: 10, xs: 2 }}
          >
            <img alt="demo" src={images.preview} style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
